$blue: #0d3691;
$green: #49bc0f;
$green-dark: #36890c;
$red: red;
$gray-light: #f1f1f1;
$gray: #5b5b5b;

.is-red {
  color: $red;
}

.is-blue {
  color: $blue;
}

.is-green {
  color: $green;
}

.has-bg-gray {
  background-color: #e0e0e0;
  background-image: url("../images/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.is-center {
  text-align: center;
}

.hide-on-mobile {
  display: none;
}

.button-primary {
  background-color: $green;
  color: white;
  padding: 1.1rem 1.5rem 1rem;
  border-radius: 10px;
  border: 0;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: $green-dark;
  }
}

.is-underlined {
  text-decoration: underline;
}

.has-yellow-circle {
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    top: -0.5rem;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/yellow-circle.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: -1;
    scale: 2;
  }
}

html,
body,
button {
  font-size: 1.1rem;
  line-height: 1.3;
  color: $gray;
  font-family: proxima-nova, sans-serif;
}

body {
  margin: 0;
}

.container {
  padding: 4rem 4vw;
}

.hero {
  padding-top: 2rem;
}

h1 {
  font-size: clamp(1.5rem, 3vw, 3rem);
  line-height: 1;
  color: $blue;
  margin-top: 0;
}

.title-primary {
  font-size: clamp(1.8rem, 3vw, 3rem);
  line-height: 1;
  text-align: center;
  color: $blue;
  margin-bottom: 4rem;
}

.title-primary,
.has-green-line {
  position: relative;
  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    bottom: -3.5rem;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/green-line.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.qualite {
  &::after {
    bottom: -1rem;
    z-index: -1;
  }
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 4vw 0;

  img {
    width: auto;
    height: 8rem;
  }

  .logo {
    height: 5rem;
  }
}

.logos {
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    width: 100%;
  }
}

.quality {
  .title {
    font-size: clamp(1.8rem, 5vw, 4rem);
    line-height: 1.1;
    color: $blue;
  }
}

.voir {
  &::after {
    scale: 1.3;
  }
}

.goals,
.participate,
.faq,
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    list-style: none;
    padding-left: 0;
  }
}

.goals,
.participate,
.footer {
  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
}

.footer {
  border-top: 3px solid $gray-light;

  & > ul {
    & > li {
      margin-bottom: 2rem;
    }
  }
}

.subfooter {
  flex-direction: column;

  a {
    text-decoration: none;
    color: $gray;
    font-size: 0.9rem;
  }
}

.faq {
  padding-top: 0;

  & > ul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1vw;

    & > li {
      border: 2px solid $gray-light;
      border-radius: 10px;
      padding: 1rem;
    }
  }

  .question-text {
    display: none;
    margin-top: 0.5rem;
  }

  .question {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: white;
    padding: 0;
    cursor: pointer;
    border: 0;

    .question-title {
      position: relative;
      color: $green;
      font-weight: bold;
      width: 95%;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -1.5rem;
        width: 1rem;
        height: 1rem;
        background-image: url("../images/arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;
        transition: scale 0.2s ease-out, rotate 0.2s ease-out;
        translate: 0 -50%;
      }
    }

    &.active {
      & + .question-text {
        display: block;
      }

      .question-title {
        &::after {
          rotate: 90deg;
        }
      }
    }

    &:hover {
      .question-title::after {
        scale: 1.3;
      }
    }
  }
}

.goals {
  overflow: hidden;

  ul {
    img {
      width: 9rem;
      height: 6rem;
    }

    li {
      color: $blue;

      &:nth-of-type(2) {
        margin-top: 3rem;
        margin-bottom: 2rem;
      }

      &::after {
        scale: 2;
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 1.4;
    }
  }
}

.participate {
  a {
    text-decoration: underline;
    font-weight: bold;
  }

  .number {
    color: $red;
    font-size: 4rem;
    font-weight: bold;

    &::after {
      scale: 4;
    }
  }

  h3 {
    font-size: clamp(1.2rem, 2vw, 2.5rem);
    color: $blue;
    margin-bottom: 0;
  }

  h4 {
    color: $green;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: clamp(1.2rem, 1.4vw, 2.5rem);
    line-height: 1;
  }

  ul {
    margin-top: 3rem;

    p {
      line-height: 1.5;
      font-size: 1rem;
    }
  }

  .wrapper {
    .img-wrapper {
      flex: 2;
    }

    button {
      flex: 1;
    }

    img {
      width: 100%;
    }
  }
}

.popup {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #0d379166;
  overflow: scroll;
  font-size: 0.8rem;

  &.show {
    display: flex;
  }
}

.popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fefefe;
  margin: 15% auto;
  padding: 4vh 4vw;
  width: 100%;
  border-radius: 10px;
}

.close {
  position: absolute;
  top: 6rem;
  right: 0.5rem;
  color: $green;
  float: right;
  font-size: 3rem;
  font-weight: bold;
  transition: scale 0.2s ease-out;
  cursor: pointer;
}

.close:hover,
.close:focus {
  scale: 1.2;
}

.popup-img {
  display: flex;
  width: 12rem;
  margin: 6rem auto 1rem;
}

.popup-title {
  display: inline-flex;
  text-align: center;
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0 8vw;
  color: $blue;
  line-height: 1.1;
}

.accept {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 1rem;
  text-decoration: underline;
  opacity: 0.8;

  a {
    color: $gray;
  }
}

.field {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: $gray-light;
  margin-bottom: 0.5rem;

  input {
    background-color: $gray-light;
    border: 0;
    padding: 0.5rem;
    border-radius: 5px;
    width: 100%;
  }

  label {
    text-transform: uppercase;
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    font-size: 1rem;
  }

  label {
    color: $blue;
  }

  input,
  .field-radio label {
    cursor: pointer;
  }

  .inputs {
    label {
      white-space: nowrap;
      margin-right: 0.5rem;
    }
  }
}

.wrapper-fields {
  width: 100%;
  margin-bottom: 1rem;
}

.wrapper-radio {
  display: flex;
  gap: 2vw;
}

.checkboxes {
  flex-direction: column;
  gap: 0.1rem;
}

.form-message {
  display: none;
  padding: 0.3rem;
  text-align: center;
  border-radius: 8px;
  margin-top: 0.5rem;
}

#success-message {
  color: green;
  border: 1px solid green;
}

#error-message {
  color: red;
  border: 1px solid red;
}

#download-button {
  margin-top: 1rem;
  background-color: #ff6200;
}

@media only screen and (min-width: 64em) {
  .container {
    padding: 4rem 10vw;
  }

  .hide-on-mobile {
    display: block;
  }

  .hide-on-desktop {
    display: none;
  }

  .logos {
    img {
      width: auto;
      height: 6rem;
    }
  }

  .hero {
    padding-top: 2rem;
  }

  .quality {
    display: flex;
    gap: 6vw;
    align-items: center;

    .texts {
      text-align: right;
      font-size: 1.2rem;
      flex: 7;
    }

    .title {
      line-height: 0.85;
      flex: 6;
    }
  }

  .goals,
  .participate,
  .footer {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6vw;

      li {
        flex: 1;
      }
    }
  }

  .footer {
    align-items: normal;

    & > ul {
      & > li {
        margin-bottom: 0;
      }
    }
  }

  .goals {
    ul {
      li {
        &:nth-of-type(2) {
          margin-top: 5rem;
        }

        &::after {
          scale: 3;
        }
      }
    }
  }

  .participate {
    & > p {
      width: 40%;
    }

    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2vw;
    }
  }

  .faq {
    padding-top: 0;

    ul {
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-start;
    }
  }

  ul.subfooter {
    gap: 0.5rem;
  }

  .popup {
    overflow: hidden;
    font-size: 1.1rem;
  }

  .popup-content {
    width: 40%;
    padding: 2vh 10vw 5vh;
  }

  .close {
    top: 1rem;
    right: 2rem;
  }

  .popup-title {
    margin-bottom: 2rem;
    padding: 0 1vw;
    font-size: 1.3rem;
  }

  .popup-img {
    margin: 0 auto 1rem;
  }
}
